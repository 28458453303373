import * as React from 'react';
import { Component } from 'react';
import { RouteComponentProps, Link } from "react-router-dom";
import { withRouter, InvitationType, GLGlobal, GLUtil } from 'gl-commonui';
import { InvitationsComPage } from '@app/components';
import { GSAdminLocale, SchoolLocale } from '@app/locales/localeid';
import { GSAdminAction } from '@app/util';
import { PathConfig } from '@app/config/pathconfig';

@withRouter
export class SchoolTrainersPage extends Component<RouteComponentProps<any>> {
    clickedBtns = []
    formatInvitationName = (id, name, pending) => {
        const { match: { params: { regionId, schoolId } } } = this.props;
        const pathSchoolCoach = regionId && schoolId ? GLUtil.pathStringify(PathConfig.SchoolTrainer, { regionId, schoolId, trainerId: id }) : null;
        return pathSchoolCoach && GLGlobal.isActionValid(GSAdminAction.SchoolTrainerHome) && !pending ?
            <Link className={'navigation-link'} to={pathSchoolCoach}>
                {name}
            </Link>
            : name 
    }
    render() {
        const { match: { params: { schoolId } } } = this.props;
        const fmtMsg = GLGlobal.intl.formatMessage;
        return (
            <InvitationsComPage
                refId={schoolId}
                typeId={InvitationType.trainer}
                typeTitle={fmtMsg({ id: GSAdminLocale.SchoolTrainerTitle })}
                intl={getIntl()}
                clickedBtns={this.clickedBtns}
                addInvitationAction={GSAdminAction.InviteSchoolTrainer}
                deleteInvitationAction={GSAdminAction.DeleteSchoolTrainer}
                sendEmailAction={GSAdminAction.SendSchoolTrainer}
                formatInvitationName={this.formatInvitationName.bind(this)}
            />
        )
    }
}

function getIntl() {
    return {
        Invite: GSAdminLocale.SchoolTrainerInvite,
        InviteTitle: SchoolLocale.InvitationInviteSchoolTrainer,
    }
}